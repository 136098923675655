import { withStyles, Theme } from '@material-ui/core/styles';
import WhiteButton from './WhiteButton';

const ExtraLargeWhiteButton = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.h2.fontSize,
    padding: `${theme.spacing(3)}px ${theme.spacing(8)}px`,
  },
}))(WhiteButton);

export default ExtraLargeWhiteButton;
