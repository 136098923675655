import React, { FC, PropsWithChildren } from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

type DataProps<P> = P & {
  bgImage: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    backgroundImage: (props: DataProps<BoxProps>) => `url("${props.bgImage}")`,
    backgroundPosition: 0,
    backgroundRepeat: 'repeat',
  },
}));

const BackgroundImageBox: FC<PropsWithChildren<
  DataProps<BoxProps>
>> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, bgImage, ...restProps } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.root} {...restProps}>
      {children}
    </Box>
  );
};

export default BackgroundImageBox;
