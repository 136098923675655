import React, { FC, PropsWithChildren } from 'react';
import {
  Button as MaterialButton,
  ButtonProps,
  capitalize,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

type DataProps<P> = P & {
  isSkewed?: boolean;
  isUppercased?: boolean;
  wide?: 'small' | 'medium' | 'large';
};

const useStyles = makeStyles((theme: Theme) => ({
  isSkewed: {
    transform: 'skew(-20deg,0deg)',

    '& > span': {
      transform: 'skew(20deg,0deg)',
    },
  },
  isUppercased: {
    textTransform: 'uppercase',
  },
  wideSmall: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  wideMedium: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  wideLarge: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

const Button: FC<PropsWithChildren<DataProps<ButtonProps>>> = ({
  children,

  isSkewed,
  isUppercased,
  wide,

  ...restProps
}) => {
  const classes = useStyles();

  return (
    <MaterialButton
      className={clsx({
        [classes.isSkewed]: isSkewed,
        [classes.isUppercased]: isUppercased,
        [classes[`wide${capitalize(wide)}`]]: wide,
      })}
      disableElevation
      disableTouchRipple
      disableRipple
      {...restProps}
    >
      {children}
    </MaterialButton>
  );
};

Button.defaultProps = {
  isSkewed: false,
  wide: 'medium',
};

export default Button;
