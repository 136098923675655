import { withStyles, Theme } from '@material-ui/core/styles';
import Button from './Button';

const WhiteButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    '&:hover, &:active, &:focus': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}))(Button);

export default WhiteButton;
