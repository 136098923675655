import { Container, Grid, Link } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import BackgroundImageBox from '../../components/BackgroundImageBox';
import { ExtraLargeWhiteButton } from '../../components/Button';
import LinkBehavior from '../../components/LinkBehavior';
import DotPng from '../../images/dot.png';

type DataProps = {
  showContactButton?: boolean;
  showPricingListButton?: boolean;
};

const ContactInfoSection: FC<DataProps> = ({
  showContactButton,
  showPricingListButton,
}) => {
  const pdfs = useStaticQuery(graphql`
    query priceListInfo2 {
      priceList: file(name: { eq: "cenik" }) {
        name
        extension
        publicURL
      }
    }
  `);

  return (
    <BackgroundImageBox component="section" bgImage={DotPng} py={10}>
      <Container>
        <Grid container justify="space-around" spacing={4}>
          {showPricingListButton && (
            <Grid item xs={10} sm={8} md={4} lg={4}>
              <ExtraLargeWhiteButton
                component={Link}
                target="_blank"
                fullWidth
                size="large"
                wide="large"
                isUppercased
                href={pdfs.priceList.publicURL}
              >
                Ceník
              </ExtraLargeWhiteButton>
            </Grid>
          )}

          {showContactButton && (
            <Grid item xs={10} sm={8} md={4} lg={4}>
              <ExtraLargeWhiteButton
                component={LinkBehavior}
                to="/contact/"
                fullWidth
                size="large"
                wide="large"
                isUppercased
              >
                Kontakt
              </ExtraLargeWhiteButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </BackgroundImageBox>
  );
};

ContactInfoSection.defaultProps = {
  showContactButton: true,
  showPricingListButton: true,
};

export default ContactInfoSection;
